import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';

export const CategoriesList = () => {
    const [data, setdata] = useState([]);
    const [categoryId, setcategoryId] = useState();
    const [formD, setformD] = useState({
        categoryId: null,
        descriptionCategory: '',
    });

    const dataListCategory = async () => {
        try {
            const response = await axios.get(ApiUrl + `categories-list`);
            const responseData = response.data;
            setdata(responseData);
            console.log('Todas la Categorias:', responseData);
            const script = document.createElement("script");
            script.src = `/assets/dataTable/dataTable.js`;
            script.async = true;
            document.body.appendChild(script);
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("Description", formD.descriptionCategory);
        try {
            const response = await axios.post(ApiUrl + 'categories-add', formData);
            const resp = response.data;
            if (resp.success) {
                toast.success("Categoría agregada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListCategory();
            } else {
                toast.error("Categoría no se ha agregada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setformD({
            ...formD,
            [name]: value
        })
    }

    const onSubmitUpdate = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("Description", formD.descriptionCategory);
        try {
            const response = await axios.post(ApiUrl + `categories-update/${formD.categoryId}`, formData);
            const resp = response.data;
            if (resp.success) {
                toast.success("Categoría actualizada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListCategory();
            } else {
                toast.error("Categoría no se ha actualizada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const deleteCategory = async (category_id) => {       
        try {
            const response = await axios.post(ApiUrl + `categories-delete/${category_id}`);
            const resp = response.data;            
                toast.success("Categoría eliminada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListCategory();
            
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    useEffect(() => {
        dataListCategory();
    }, [])

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className=" card-header">
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <b>LISTA DE CATEGORÍAS</b>
                                </div>
                                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                                    <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addModal" ><b>CREAR CATEGORÍA</b><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className='table table-hover ' id="dataTable"  >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Descripción</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((dataCategory, i) => (
                                        <tr key={dataCategory.categories_id}>
                                            <td>{i + 1}</td>
                                            <td>{dataCategory.Description}</td>
                                            <td>
                                                <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editProductModal" onClick={() => {setformD({categoryId:dataCategory.categories_id,descriptionCategory: dataCategory.Description});}}><i className="fas fa-pen" aria-hidden="true"></i></button>
                                                <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setcategoryId(dataCategory.categories_id)}><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* ELIMINAR CATEGORIAS */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar Categoría</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Está seguro que desea eliminar <b></b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteCategory(categoryId)} >Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* CREAR CATEGORIAS */}
            <div className="modal fade" id="addModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>CREAR CATEGORÍA</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Descripción</label>
                                            <input type="text" name='descriptionCategory' className="form-control" onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" >Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* EDITAR CATEGORIAS */}
            <div className="modal fade" id="editProductModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR CATEGORÍA</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmitUpdate}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Descripción</label>
                                            <input type="text" name='descriptionCategory' className="form-control" value={formD.descriptionCategory} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" >Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}